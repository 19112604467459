<script>
  import FaTwitter from 'svelte-icons/fa/FaTwitter.svelte';
  import FaInstagram from 'svelte-icons/fa/FaInstagram.svelte';
  import FaFacebook from 'svelte-icons/fa/FaFacebook.svelte';
  import FaSoundcloud from 'svelte-icons/fa/FaSoundcloud.svelte';
</script>

<footer class="flex w-full mx-auto justify-center max-w-7xl text-center py-10 items-center">
  <div class="w-1/3">
    <ul>
      <li class="text-gray-400">BOOKING / INQUIRIES / MGMT</li>
      <li><a href="mailto:info@highereducationrecords.com">info@highereducationrecords.com</a></li>
    </ul>
  </div>

  <div class="w-1/3">
    <h3 class="text-3xl">LET'S <strong>CONNECT</strong></h3>
    <ul class="icons flex justify-evenly mt-3">
      <li><a href="https://twitter.com/getmosesmusic" target="_blank" rel="noopener noreferrer"><FaTwitter /></a></li>
      <li><a href="https://www.instagram.com/getmosesmusic/" target="_blank" rel="noopener noreferrer" ><FaInstagram /></a></li>
      <li><a href="https://www.facebook.com/getmosesmusic/" target="_blank" rel="noopener noreferrer"><FaFacebook /></a></li>
      <li><a href="https://soundcloud.com/getmosesmusic" target="_blank" rel="noopener noreferrer"><FaSoundcloud /></a></li>
    </ul>
  </div>

  <div class="w-1/3">
    <p style="color: rgb(177, 177, 177);"><small>Copyright © 2017-2021 Moses</small></p>
  </div>
</footer>

<style>
  .icons li { width: 30px; }
</style>
