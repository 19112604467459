<script>
  import { page } from '$app/stores';
  import Logo from '$lib/components/Logo.svelte';

  let y;
</script>

<svelte:window bind:scrollY={y}/>

<nav>
  <ul>
    {#if $page.path !== '/'}
    <li class="w-32 inline-block">
      <a href="/"><Logo /></a>
    </li>
    {/if}
    <li><a href="/music/">Music</a></li>
    <li><a href="/videos/">Videos</a></li>
    <li><a href="/about/">About</a></li>
    <li><a href="/contact/">Contact</a></li>
    <li><a href="/moses-EPK.pdf" target="_blank" rel="noopener noreferrer">EPK</a></li>
  </ul>
  <div class="transition-all duration-500 w-full h-full absolute left-0 top-0 z-40" style={`background: url(/epk-bg.jpg); opacity: ${y < 100 ? '0' : 1}`} />
</nav>

<style>
  nav {
    @apply transition-all fixed w-full mx-auto py-6 bg-contain bg-no-repeat bg-center;
  }
  ul {
    @apply flex space-x-3 list-none uppercase justify-center items-center relative z-50;
  }
</style>
